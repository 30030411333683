import PropTypes, { number } from 'prop-types'
import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'
import { getParsedFilterValues } from 'SRC/modules/ads/filter/functions'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getCurrentSubCategory, getParentCategory } from 'SRC/modules/categories/selectors'
import { getPaginationParams } from 'SRC/modules/ads/pagination/selectors'
import { getPageMetaTagsSingle } from 'SRC/modules/common/selectors'
import { globalOptions } from 'SRC/core/constants'
import { Item } from '../Item'
import countriesArr from '../../../../../../../public/data/countriesArr.json'

const getH1Text = ({
  pageMetaTagsSingle,
  urlSpecTitle,
  categoryTitle,
  countryBc,
  cityBc
}) => {
  let title = urlSpecTitle || pageMetaTagsSingle.h1 || categoryTitle

  if (cityBc?.seo) title += ` ${cityBc.text}`
  if (countryBc?.seo) title += ` ${countryBc.text}`

  return title
}

const getH2Text = ({
  pageMetaTagsSingle,
  urlSpecTitle,
  countryBc,
  cityBc
}) => {
  let title = ''

  if (urlSpecTitle) {
    if (pageMetaTagsSingle.h2) title = `${pageMetaTagsSingle.h2.split('-')[0]} - ${urlSpecTitle}`
    else title = urlSpecTitle
  } else {
    title = pageMetaTagsSingle.h2
  }

  if (title && cityBc?.seo) title += ` ${cityBc.text}`
  if (title && countryBc?.seo) title += ` ${countryBc.text}`

  return title
}

const BCIcon = () => {
  return (
    <ReactSVG src='/icons/home-breadcrumbs.svg' beforeInjection={svg => {
      svg.setAttribute('style', 'width: 20px; height: auto; margin-right: 5px;')
    }} wrapper='span' className='ad-breadcrumbs__icon' />
  )
}

const AdsCountText = ({ count }) => {
  if (!count) return <span />
  if (count === 1) {
    return <span style={{ marginLeft: '0.5rem', fontSize: '1rem' }}><strong>1</strong> oglas pronađen</span>
  }

  if (count === 2) {
    return <span style={{ marginLeft: '0.5rem', fontSize: '1rem' }}><strong>2</strong> pronađena oglasa</span>
  }

  return <span style={{ marginLeft: '0.5rem', fontSize: '1rem' }}><strong>{count}</strong> pronađenih oglasa</span>
}

const ParentCategory = ({ adsCount, parentCategory, h1Text, h2Text }) => {
  const breadCrumbItems = [{ key: 'home', as: '/', href: { pathname: '/' }, text: 'Početna', isLast: false }]

  let baseAs = `/${parentCategory.seo}`
  let baseHref = { pathname: '/search', query: { parentCategory: parentCategory.seo, '0': [''] } }

  breadCrumbItems.push({ key: 'parentCategory', as: baseAs, href: baseHref, text: parentCategory.title, isLast: true })

  return (
    <div className='ad-breadcrumbs ad-breadcrumbs__ads_list'>
      <BCIcon />

      {breadCrumbItems.map(breadCrumbItem => (
        <Item
          key={breadCrumbItem.key}
          as={breadCrumbItem.as}
          href={breadCrumbItem.href}
          text={breadCrumbItem.text}
          isLast={breadCrumbItem.isLast}
        />
      ))}

      <AdsCountText count={adsCount} />

      <h1>{h1Text}</h1>
      {h2Text ? <h2>{h2Text}</h2> : null}
    </div>
  )
}

const Group = ({ adsCount, group, parentCategory, h1Text, h2Text }) => {
  const breadCrumbItems = [{ key: 'home', as: '/', href: { pathname: '/' }, text: 'Početna', isLast: false }]

  let baseAs = `/oglasi/${group.seo}`
  let baseHref = { pathname: '/search', query: { category: group.seo, '0': [''] } }

  if (parentCategory && parentCategory.seo !== group.seo) {
    baseAs = `/oglasi/${parentCategory.seo}/${group.seo}`
    baseHref = { pathname: '/search', query: { parentCategory: parentCategory.seo, category: group.seo, '0': [''] } }
  }

  breadCrumbItems.push({ key: 'group', as: baseAs, href: baseHref, text: group.title, isLast: true })

  return (
    <div className='ad-breadcrumbs ad-breadcrumbs__ads_list'>
      <BCIcon />

      {breadCrumbItems.map(breadCrumbItem => (
        <Item
          key={breadCrumbItem.key}
          as={breadCrumbItem.as}
          href={breadCrumbItem.href}
          text={breadCrumbItem.text}
          isLast={breadCrumbItem.isLast}
        />
      ))}

      <AdsCountText count={adsCount} />

      <h1>{h1Text}</h1>
      {h2Text ? <h2>{h2Text}</h2> : null}
    </div>
  )
}

const AdListBreadcrumbs = ({
  pageMetaTagsSingle,
  pageUrlItems,
  category,
  parentCategory,
  group,
  paginationParams
}) => {
  const router = useRouter()

  if (!pageMetaTagsSingle || (!category && !group && !parentCategory)) return null

  const adsCount = paginationParams?.totalCount

  const possibleValues = (router.query['0'] || '').split('/')

  const filterValues = getParsedFilterValues({
    filterValues: router.query,
    pageMetaTags: pageMetaTagsSingle,
    pageUrlItems: pageUrlItems || [],
    possibleValues
  })

  let countryBc = null
  let cityBc = null

  if (filterValues?.cityBc) cityBc = filterValues.cityBc
  if (filterValues?.country && Array.isArray(countriesArr)) {
    const country = countriesArr.find(row => row[1] === Number(filterValues.country))
    if (country && country[0] && country[2]) countryBc = { seo: country[0], text: country[2] }
  }

  if (parentCategory && (!group || group.seo === parentCategory.seo || (category && category.seo === parentCategory.seo))) {
    const h1Text = getH1Text({
      categoryTitle: parentCategory.title,
      pageMetaTagsSingle,
      cityBc,
      countryBc
    })

    const h2Text = getH2Text({
      pageMetaTagsSingle,
      cityBc,
      countryBc
    })

    return (
      <ParentCategory
        adsCount={adsCount}
        parentCategory={parentCategory}
        h1Text={h1Text}
        h2Text={h2Text}
      />
    )
  }

  if (group && !category) {
    const h1Text = getH1Text({
      categoryTitle: group.title,
      pageMetaTagsSingle,
      cityBc,
      countryBc
    })

    const h2Text = getH2Text({
      pageMetaTagsSingle,
      cityBc,
      countryBc
    })

    return (
      <Group
        adsCount={adsCount}
        group={group}
        parentCategory={parentCategory}
        h1Text={h1Text}
        h2Text={h2Text}
      />
    )
  }

  const breadCrumbItems = [{ key: 'home', as: '/', href: { pathname: '/' }, text: 'Početna', isLast: false }]

  let baseAs = `/oglasi/${category.seo}`
  let baseHref = { pathname: '/search', query: { category: category.seo, '0': [''] } }

  let urlSpecTitle = ''

  if (parentCategory && parentCategory.seo !== category.seo) {
    baseAs = `/oglasi/${parentCategory.seo}/${category.seo}`
    baseHref = { pathname: '/search', query: { parentCategory: parentCategory.seo, category: category.seo, '0': [''] } }
  }

  breadCrumbItems.push({ key: 'category', as: baseAs, href: baseHref, text: category.title, isLast: false })

  let brandAs = baseAs
  let serviceAs = baseAs
  const brandHref = { ...baseHref, query: { ...baseHref.query, '0': [''] } }
  const serviceHref = { ...baseHref, query: { ...baseHref.query, '0': [''] } }

  if (pageMetaTagsSingle && Array.isArray(pageMetaTagsSingle.urlSpecs) && Array.isArray(filterValues.specifications)) {
    pageMetaTagsSingle.urlSpecs.forEach(urlSpec => {
      const findSpec = filterValues.specifications.find(spec => Number(spec.id) === urlSpec.id)

      if (findSpec && findSpec.value) {
        const specOptionsIndex = urlSpec.options.findIndex(urlSpecOption => urlSpecOption === findSpec.value)

        if (specOptionsIndex !== -1) {
          const urlSpecSeo = urlSpec.seo[specOptionsIndex]

          baseAs = `${baseAs}/${urlSpecSeo}`
          baseHref = { ...baseHref, query: { ...baseHref.query, '0': [`${baseHref.query['0'][0]}/${urlSpecSeo}/`] } }

          breadCrumbItems.push({
            key: `spec-${urlSpecSeo}`,
            as: baseAs,
            href: baseHref,
            text: urlSpec.title[specOptionsIndex],
            isLast: false
          })

          urlSpecTitle = urlSpec.title[specOptionsIndex]
        }
      }
    })
  }

  if (filterValues?.brandBc?.seo && ['BrandModel', 'BrandOnly'].includes(category.requiredAdOptions)) {
    brandAs = `${brandAs}/proizvodjac-${filterValues.brandBc.seo}`
    brandHref.query['0'] = [`${brandHref.query['0'][0]}proizvodjac-${filterValues.brandBc.seo}`]
    breadCrumbItems.push({ key: 'brand', as: brandAs, href: brandHref, text: filterValues.brandBc.text, isLast: false })

    if (filterValues?.modelBc?.seo && category.requiredAdOptions === 'BrandModel') {
      const modelAs = `${brandAs}/model-${filterValues.modelBc.seo}`
      const modelHref = {
        ...brandHref,
        query: { ...brandHref.query, '0': [`${brandHref.query['0'][0]}/model-${filterValues.modelBc.seo}`] }
      }

      breadCrumbItems.push({ key: 'model', as: modelAs, href: modelHref, text: filterValues.modelBc.text, isLast: false })

      let countryAs = modelAs
      let countryHref = modelHref

      if (countryBc) {
        countryAs = `${modelAs}/${countryBc.seo}`
        countryHref = {
          ...modelHref, query: { ...modelHref.query, '0': [`${modelHref.query['0'][0]}/${countryBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: countryBc.text, isLast: false })
      }

      if (cityBc?.seo) {
        const cityAs = `${countryAs}/grad-${cityBc.seo}`
        const cityHref = {
          ...countryHref, query: { ...countryHref.query, '0': [`${countryHref.query['0'][0]}/grad-${cityBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: cityBc.text, isLast: false })
      }
    } else {
      let countryAs = brandAs
      let countryHref = brandHref

      if (countryBc) {
        countryAs = `${brandAs}/${countryBc.seo}`
        countryHref = {
          ...brandHref, query: { ...brandHref.query, '0': [`${brandHref.query['0'][0]}/${countryBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: countryBc.text, isLast: false })
      }

      if (cityBc?.seo) {
        const cityAs = `${countryAs}/grad-${cityBc.seo}`
        const cityHref = {
          ...countryHref, query: { ...countryHref.query, '0': [`${countryHref.query['0'][0]}/grad-${cityBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: cityBc.text, isLast: true })
      }
    }
  }

  if (filterValues?.serviceBc?.seo && category.requiredAdOptions === 'Services') {
    const serviceAs = `${baseAs}/usluga-${filterValues.serviceBc.seo}`
    serviceHref.query['0'] = [`${serviceHref.query['0'][0]}usluga-${filterValues.serviceBc.seo}`]
    breadCrumbItems.push({ key: 'service', as: serviceAs, href: serviceHref, text: filterValues.serviceBc.text, isLast: false })

    if (filterValues?.serviceTypeBc?.seo) {
      const serviceTypeAs = `${serviceAs}/tip-usluge-${filterValues.serviceTypeBc.seo}`
      const serviceTypeHref = {
        ...serviceHref,
        query: { ...serviceHref.query, '0': [`${serviceHref.query['0'][0]}/tip-usluge-${filterValues.serviceTypeBc.seo}`] }
      }

      breadCrumbItems.push({
        key: 'serviceType',
        as: serviceTypeAs,
        href: serviceTypeHref,
        text: filterValues.serviceTypeBc.text,
        isLast: false
      })

      let countryAs = serviceTypeAs
      let countryHref = serviceTypeHref

      if (countryBc) {
        countryAs = `${serviceTypeAs}/${countryBc.seo}`
        countryHref = {
          ...serviceTypeHref, query: { ...serviceTypeHref.query, '0': [`${serviceTypeHref.query['0'][0]}/${countryBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: countryBc.text, isLast: false })
      }

      if (cityBc?.seo) {
        const cityAs = `${countryAs}/grad-${cityBc.seo}`
        const cityHref = {
          ...countryHref, query: { ...countryHref.query, '0': [`${countryHref.query['0'][0]}/grad-${cityBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: cityBc.text, isLast: false })
      }
    } else {
      let countryAs = serviceAs
      let countryHref = serviceHref

      if (countryBc) {
        countryAs = `${serviceAs}/${countryBc.seo}`
        countryHref = {
          ...serviceHref, query: { ...serviceHref.query, '0': [`${serviceHref.query['0'][0]}/${countryBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: countryBc.text, isLast: false })
      }

      if (cityBc?.seo) {
        const cityAs = `${countryAs}/grad-${cityBc.seo}`
        const cityHref = {
          ...countryHref, query: { ...countryHref.query, '0': [`${countryHref.query['0'][0]}/grad-${cityBc.seo}`] }
        }

        breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: cityBc.text, isLast: true })
      }
    }
  }

  const lastBc = breadCrumbItems[breadCrumbItems.length - 1]

  if ((filterValues?.cityBc || filterValues?.country) && !['city', 'country'].includes(lastBc.key)) {
    let countryAs = baseAs
    let countryHref = baseHref

    if (countryBc) {
      countryAs = `${baseAs}/${countryBc.seo}`
      countryHref = {
        ...baseHref, query: { ...baseHref.query, '0': [`${baseHref.query['0'][0]}/${countryBc.seo}`] }
      }

      breadCrumbItems.push({ key: 'country', as: countryAs, href: countryHref, text: countryBc.text, isLast: false })
    }

    if (filterValues?.cityBc && lastBc.key !== 'city') {
      const cityAs = `${countryAs}/grad-${cityBc.seo}`
      const cityHref = {
        ...countryHref, query: { ...countryHref.query, '0': [`${countryHref.query['0'][0]}/grad-${cityBc.seo}`] }
      }

      breadCrumbItems.push({ key: 'city', as: cityAs, href: cityHref, text: cityBc.text, isLast: false })
    }
  }

  const h2Text = getH2Text({
    pageMetaTagsSingle,
    urlSpecTitle,
    cityBc,
    countryBc
  })

  const h1Text = getH1Text({
    categoryTitle: category.title,
    urlSpecTitle,
    pageMetaTagsSingle,
    cityBc,
    countryBc
  })

  return (
    <div className='ad-breadcrumbs ad-breadcrumbs__ads_list'>
      <BCIcon />

      {breadCrumbItems.map((breadCrumbItem, i) => (
        <Item
          key={breadCrumbItem.key}
          as={breadCrumbItem.as}
          href={breadCrumbItem.href}
          text={breadCrumbItem.text}
          isLast={i === breadCrumbItems.length - 1}
        />
      ))}

      <AdsCountText count={adsCount} />

      <h1>{h1Text}</h1>
      {h2Text ? <h2>{h2Text}</h2> : null}
    </div>
  )
}

AdListBreadcrumbs.propTypes = {
  pageMetaTagsSingle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    urlSpecs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      seo: PropTypes.arrayOf(PropTypes.string),
      options: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.arrayOf(PropTypes.string)
    })),
    h1: PropTypes.string,
    h2: PropTypes.string
  }),
  pageUrlItems: PropTypes.object,
  category: PropTypes.shape({
    requiredAdOptions: PropTypes.oneOf(['BrandModel', 'BrandOnly', 'Services']),
    title: PropTypes.string,
    seo: PropTypes.string,
  }),
  parentCategory: PropTypes.shape({
    title: PropTypes.string,
    seo: PropTypes.string,
  }),
  group: PropTypes.shape({
    title: PropTypes.string,
    seo: PropTypes.string,
  }),
  paginationParams: PropTypes.shape({
    totalCount: PropTypes.number
  }).isRequired
}

AdsCountText.propTypes = {
  count: PropTypes.number.isRequired
}

Group.propTypes = {
  group: PropTypes.shape({
    title: PropTypes.string,
    seo: PropTypes.string,
  }),
  parentCategory: PropTypes.shape({
    title: PropTypes.string,
    seo: PropTypes.string,
  }),
  pageMetaTagsSingle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    h1: PropTypes.string
  }),
  adsCount: PropTypes.number.isRequired,
  h1Text: PropTypes.string.isRequired,
  h2Text: PropTypes.string
}

ParentCategory.propTypes = {
  parentCategory: PropTypes.shape({
    title: PropTypes.string,
    seo: PropTypes.string,
  }),
  pageMetaTagsSingle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    h1: PropTypes.string
  }),
  adsCount: PropTypes.number.isRequired,
  h1Text: PropTypes.string.isRequired,
  h2Text: PropTypes.string
}

const mapStateToProps = state => {
  return {
    pageMetaTagsSingle: getPageMetaTagsSingle(state),
    group: getCurrentGroup(state),
    category: getCurrentSubCategory(state),
    parentCategory: getParentCategory(state),
    paginationParams: getPaginationParams(state)
  }
}

export default connect(mapStateToProps)(AdListBreadcrumbs)
